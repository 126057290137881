.edit-profile-wrapper {
  display: flex;
  justify-content: center;
  .container {
    padding: 60px 20px 100px;
    width: 624px;
    color: #222;
    font-size: 16px;
    .title-wrap {
      color: #888888;
      font-size: 14px;
      line-height: 120%;
      padding: 24px 0;
      .title {
        color: #000;
        font-weight: 700;
        font-size: 20px;
      }
    }
    .twitter-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .account {
        border: 1px solid #222222;
        border-radius: 2px;
        padding: 0 8px;
        height: 32px;
        line-height: 30px;
        .iconfont {
          padding-right: 5px;
          cursor: pointer;
        }
      }
      .twitter-switch {
        display: flex;
      }
    }
    .main-button {
      padding: 0 8px;
      background: #FFC400;
      border-radius: 2px;
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      width: 168px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .iconfont {
        padding-right: 5px;
      }
    }
    .upload-content {
      margin-bottom: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .avatar {
        border-radius: 64px;
        position: relative;
        z-index: 1;
        width: 64px;
        height: 64px;
        display: block;
        margin-right: 16px;
      }
      .upload-button {
        width: 100%;
        height: 100%;
        background: #FFC400;
        color: #222;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .chakra-form-control {
      margin-bottom: 20px;
    }
    .twitter-control {
      margin-bottom: 0;
      margin-left: 11px;
      .chakra-form__label {
        font-size: 12px;
        font-weight: 400;
        padding-left: 4px;
      }
    }
    .chakra-form__label {
      font-weight: 700;
      line-height: 20px;
      font-size: 14px;
    }
    .chakra-form__helper-text {
      margin-top: 6px;
      font-size: 12px;
      color: #888888;
    }
    .chakra-input, .chakra-textarea {
      border: 1px solid #888888;
      border-radius: 2px;
      font-size: 14px;
      padding: 8px 12px;
    }
    .chakra-input {
      height: 36px;
    }
    .chakra-textarea {
      height: 120px;
    }
    
    .chakra-switch__input {
      outline: none;
      border: none;
    }
    .chakra-switch__track {
      background: #ccc;
    }
    .chakra-switch__track[data-focus] {
      box-shadow: none;
    }
    .chakra-switch__track[data-checked] {
      background: #FFC400;
    }
    .chakra-switch__thumb {
      background: #FCFCFC;
      border: 1px solid #888888;
    }
    .chakra-switch__thumb[data-checked] {
      border-color: #222;
    }
    .button-group {
      padding-top: 4px;
    }
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 2px;
    width: 165px;
    height: 40px;
    overflow: hidden;
    border: none;
    margin: 0;
  }
}
@primary-color: #157AAC;