@import '~antd/dist/antd.css';
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700'); */
@import url('@/static/font/iconfont.css');

body,
html {
  background-color: white;
}

/* reset chakra style */
.chakra-modal__overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

/* reset viewerjs style */

.viewer-custom-close-button {
  width: 35px !important;
  height: 35px !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  right: 0 !important;
}
.viewer-button:before,
.viewer-close:before {
  font-family: 'iconfont' !important;
  font-size: 35px !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e636' !important;
  color: #fff !important;
}
.viewer-close:before {
  background-image: none !important;
}
.viewer-button:before {
  position: absolute !important;
  top: 18px !important;
  left: 0 !important;
}
.viewer-button:focus {
  box-shadow: none !important;
}
.viewer-image-wrapper {
  position: relative;
  display: inline-block;
}
image[viewer-transition] {
  margin-top: 141px !important;
}

.remove-focus:focus {
  box-shadow: none !important;
}

.css-xxkadm[data-checked] {
  background: none !important;
  background-color: #ffc400 !important;
  color: #222 !important;
  border: none !important;
}
.css-xxkadm:focus {
  border: none !important;
  box-shadow: none !important;
}
.css-xxkadm:focus-visible {
  border: none !important;
  box-shadow: none !important;
}
.rounded-none {
  border-radius: 0px !important;
}
.h-9 {
  height: 2.25rem !important;
}
.text-size-14 {
  font-size: 14px !important;
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

@media (min-width: 1024px) {
  .chakra-select__wrapper {
    width: 440px !important;
  }
}

.text-size-12 {
  font-size: 12px !important;
}
.items-start {
  align-items: baseline !important;
}

.line {
  z-index: 1;
}
.line::after {
  content: '';
  transform: translateY(-1em);
  height: 0;
  border: 2px solid #ffc300;
  width: 60%;
  display: inline-block;
}
.css-zipzvv {
  white-space: inherit !important;
}
.css-zipzvv{
white-space: inherit !important;
}

/* antd */
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #FFC400;
  color: #222;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #FFC400;
}
.ant-picker-today-btn, .ant-picker-today-btn:hover {
  color: #222;
  font-weight: 700;
}
.ant-picker-month-btn:hover, .ant-picker-year-btn:hover, .ant-picker-header-view:hover, .ant-picker-decade-btn:hover, .ant-picker-now-btn:hover {
  color: #222 !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgba(255, 196, 0, 0.4)
}
.ant-picker-ok .ant-btn-primary {
  background: #FFC400;
  color: #222;
  border-color: #FFC400;
}
