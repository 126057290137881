@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800"); */
/* body,
html {
  
} */
* {
  margin: 0;
  padding: 0;
  /* background-color: #fcfcfc; */

  font-family: SFRounded, ui-rounded, SF Pro Rounded, system-ui, Helvetica Neue,
    Arial, Helvetica, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}
