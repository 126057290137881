.space-index-wrapper {
  .title-wrap {
    .title {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    .tips {
      color: #888;
    }
    margin-bottom: 20px;
  }
  .search-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #222;
    line-height: 1;
    margin-bottom: 40px;
    .select-wrapper {
      width: 105px;
    }
    .chakra-select__wrapper {
      width: 100% !important;
    }
    .chakra-select, .chakra-input {
      height: 40px;
      box-sizing: border-box;
      border-width: 1px;
      border-radius: 1px;
      font-size: 14px;
    }
    .chakra-select {
      padding: 8px !important;
    }
  }
  .space-list-wrap {
    .space-list-content {
      display: grid;
    }
    .space-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__img {
        width: 96px;
        height: 96px;
        display: block;
        border-radius: 50%;
      }
      &-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
      }
      &__name {
        padding-right: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
      &__icon {
        width: 14px;
        height: 14px;
        display: block;
      }
    }
    .content-item {
      border-width: 1px;
      border-radius: 4px;
      padding: 32px;
      display: inline-block;
    }
    .content-item:hover {
      background: #FFC400;
      .space-item {
        &__name {
          color: #222222;
        }
      }
    }
  }
  .load-more-button {
    width: 150px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: 1px solid #3E3E3E;
    border-radius: 1px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    margin: 60px auto 0;
    cursor: pointer;
  }
}
@primary-color: #157AAC;