.min-h-200 {
  min-height: 200px;
}

.text-size-56 {
  font-size: 56px;
}
.text-size-20 {
  font-size: 20px;
}
.text-size-32 {
  font-size: 32px;
}
.text-size-14 {
  font-size: 14px;
}
.font-color-888 {
  color: #888888;
}
.font-color-base {
  color: #ffc400;
}

.bg-color-base {
  background-color: #ffc400;
}
.bg-color-1d {
  background-color: #1d1d1d;
}

.text-color-222 {
  color: #222222 !important;
}
.font-semibold{
  font-weight: 600 !important;
}

.font-size-16 {
  font-size: 16px;
}
.text-size-40 {
  font-size: 40px;
}
.text-desc-color {
  color: #4e4e4e;
}
.color-fc {
  color: #fcfcfc;
}
.h-72 {
  height: 72px;
}
.img-w-24 {
  width: 24px;
}

.bg-css {
  position: absolute;
  width: 641px;
  height: 542px;
  left: 653px;
  top: 882px;

  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.apply-css {
  /* position: absolute; */
  max-width: 720px;
  /* min-width: 400px; */
  min-height: 480px;
  /* padding: 20px 20px; */
  /* top: 65px; */
  /* left: 120px; */
  /* padding-top: 60px; */
  /* background-color: #f4f4f4; */
  /* opacity: 0.9; */
}
.p-rel {
  height: 428px;
  position: relative;
}
.p-abs {
  position: absolute;
}
.w-35per {
  width: 35%;
}

.btn-middle {
  align-items: center;
}

.height-345 {
  height: 22em;
}

.bg-img{
  height: 810px;
  animation: change 30s steps(1) infinite;
  background-size: cover !important;
  /* background:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../static/image/big-banner.png) no-repeat center; */
}
@keyframes change {
  0% {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://creatordao.imgix.net/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2Fbanner%2Fbig-banner-1.jpg?s=294924d5268c02d9492f61231785fe5a) no-repeat center;
  }
  33% {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://creatordao.imgix.net/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2Fbanner%2Fbig-banner-2.jpg?s=50582f9937617ef9526c1095721e4873) no-repeat center;
  }
  66% {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://creatordao.imgix.net/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2Fbanner%2Fbig-banner-3.jpg?s=7322e84474623f8f855c2bdc481e4ff5) no-repeat center;
  }
  }
.text-color-fc{
  color: #fcfcfc;
}
.text-color-ff{
  color: #ffffff;
}
.ml-120{
  margin-left: 120px;
}

.bg-color-fff{
  background-color: #ffffff;
}
/* .w-317{
  width: 317px;
} */
.text-size-24 {
  font-size: 24px;
}
.max-w-600{
  max-width: 600px;
}
.twitter-share-button {
  border-radius: 2px;
}
.twitter-share-button .iconfont {
  padding-right: 4px;
  margin-top: 2px;
}