.my-gallery-wrapper {
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      min-width: 5.5rem;
      min-height: 5.5rem;
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
    }
    .right {
      color: #888888;
      font-size: 16px;
      line-height: 24px;
      .name-wrap {
        display: flex;
        align-items: center;
        .name {
          color: #222222;
          font-weight: 700;
          font-size: 1.5rem;
          padding-bottom: 8px;
        }
        .icon-edit {
          width: 14px;
          height: 14px;
          display: block;
          margin-left: 12px;
          margin-bottom: 4px;
          cursor: pointer;
        }
      }
      
      .intro {
        max-width: 352px;
        margin-bottom: 8px;
        overflow:hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
      }
      .icon-group {
        margin-top: 10px;
        margin-bottom: 20px;
        color: #222222;
        line-height: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .iconfont {
          margin-right: 15px;
        }
        .icon-discord {
          width: 20px;
        }
      }
      .edit-button {
        border: 1px solid #D9D9D9;
        border-radius: 1px;
        padding: 6px 12px;
        line-height: 16px;
        font-size: 12px;
        color: #222;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
  .content-item.hover {
    filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.12));
  }
  .content-wrap {
    margin-top: 50px;
    .content-item {
      position: relative;
      overflow: hidden;
      display: inline-block;
      .m-auto {
        width: 100%;
        height: 100%;
      }
      &__img {
        width: 100%;
      }
      &__tips {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        padding: 20px 12px;
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
      &__top_tips {
        background: #ccc;
        padding: 10px 12px;
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        // color: #fff;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
      &__video {
        position: relative;
        .icon-play {
          line-height: 1;
          font-size: 80px;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;        
          cursor: pointer;  
        }
      }
    }
    .upload-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #888888;
      font-size: 16px;
      line-height: 22px;
      border: 1px dashed #888;
      padding: 20px 0;
      .iconfont {
        font-size: 30px;
        color: #222;
        padding-bottom: 16px;
      }
    }
  }
  .load-more {
    margin-top: 40px;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 32px;
    border: 1px solid #CCCCCC;
    border-radius: 1px;
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  a:hover {
    color: #222;
  }
}

#chakra-modal-personal-image-preview {
  background: transparent;
  border: none;
  box-shadow: none;
  .chakra-modal {
    &__body {
      padding: 0;
    }
  }
  .dialog-close-btn {
    display: inline-block;
    line-height: 1;
    position: absolute;
    top: -18px;
    right: 0;
    color: #fff;
    font-size: 34px;
    cursor: pointer;
  }
}
@primary-color: #157AAC;