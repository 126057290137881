.top {
  background-color: #35c1da;
}
.choose-border {
  border: 1px sold #ddd;
}
.header-h {
  height: 6.25rem;
}
.bg-color-black {
  background-color: #222222;
}
.nav-text-color {
  color: #888888;
}
.text-nav-focus-color {
  border-bottom:2px solid #FFC300;
  /* background-color: #222222 !important; */
  color: #ffffff !important;
}
.text-nav-focus-color-other {
  border-bottom:2px solid #FFC300;
  /* background-color: #222222 !important; */
  /* color: #ffffff !important; */
}

.height-24 {
  height: 24px;
}
.h-48 {
  height: 48px;
}
.image-36 {
  width: 36px;
  height: 36px;
  border-radius: 18px;
}
.w-188 {
  width: 188px;
}
.border-css-000 {
  border: 1px solid #000;
}

.header-icon-home {
  cursor: pointer;
  color: #000;
  font-size: 26px;
}
.header-icon-profile {
  font-size: 20px;
  color: #000;
  padding-left: 2px;
}

.header-person-menu {
  margin-left: 16px;
}

.header-upload-btn {
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  color: #222222;
  font-weight: 700;
  font-size: 16px;
  background: #ffc400;
  border-radius: 1px;
  margin-left: 25px;
  cursor: pointer;
}
.top-100{
  top:100px;
}
.text-color-fff{
  color: #ffffff !important;
}
.text-nav-focus-color:hover{
  color: #eee !important;
}

.w-177{
  width: 177px;
}
.border-drawer-css{
  border-bottom: 2px solid #fcfcfc;
  box-shadow:0px 2px 1px -2px rgba(0, 0, 0, 0.1);
  ;
}