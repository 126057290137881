.gallery-page {
  color: #222;
  .title-wrap {
    margin-bottom: 40px;
    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 12px;
    }
    .tips {
      color: #888888;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .gallery-item {
    position: relative;
    &-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
      z-index: 1;
      color: #fff;
      padding: 14px 16px 20px 16px;
      box-sizing: border-box;
      &__top {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        .icon-view {
          font-size: 15px;
          padding-right: 5px;
        }
      }
      &__bottom {
        .artist-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
          img {
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
        .art-title {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
        }
      }
      
    }
  }
  .gallery-item.hover {
    filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.12));
    cursor: pointer;
  }
  .load-more-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    width: 160px;
    padding: 16px 0;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 1px;
    margin: 66px auto;
    cursor: pointer;
  }
}

.ril__toolbar {
  background-color: transparent;
}
.ril__toolbarSide > .ril-toolbar__item:nth-child(1), .ril__toolbarSide > .ril-toolbar__item:nth-child(2) {
  visibility: hidden;
}
@primary-color: #157AAC;