.bounty-create-wrapper {
  display: flex;
  justify-content: center;
  .container {
    padding: 60px 20px 100px;
    width: 624px;
    color: #222;
    font-size: 16px;
    .default {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 36px;
      &-button {
        background: #222;
        border-radius: 1px;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        height: 36px;
        line-height: 36px;
        padding: 0 25px;
        cursor: pointer;
      }
    }
    .title-wrap {
      color: #888888;
      font-size: 14px;
      line-height: 120%;
      padding: 24px 0;
      .title {
        color: #000;
        font-weight: 700;
        font-size: 20px;
      }
    }
    .twitter-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .account {
        border: 1px solid #222222;
        border-radius: 2px;
        padding: 0 8px;
        height: 32px;
        line-height: 30px;
        .iconfont {
          padding-right: 5px;
          cursor: pointer;
        }
      }
      .twitter-switch {
        display: flex;
      }
    }
    .main-button {
      padding: 0 8px;
      background: #FFC400;
      border-radius: 2px;
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      width: 168px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .iconfont {
        padding-right: 5px;
      }
    }
    .chakra-form-control {
      margin-bottom: 20px;
    }
    .chakra-form__label {
      font-weight: 700;
      line-height: 20px;
      font-size: 14px;
    }
    .chakra-form__helper-text {
      margin-top: 6px;
      font-size: 12px;
      color: #888888;
    }
    .chakra-input, .chakra-textarea, .chakra-numberinput input, .deadline-input {
      border: 1px solid #222;
      border-radius: 2px;
      font-size: 14px;
      padding: 8px 12px;
    }
    .chakra-form__required-indicator {
      display: none;
    }
    .deadline-input {
      width: 100%;
      .icon {
        width: 18px;
        height: 18px;
        display: block;
      }
    }
    .chakra-input {
      height: 36px;
    }
    .chakra-textarea {
      height: 120px;
    }
    .tag-select, .token-select {
      color: #222;
      .ant-select-selector {
        padding: 0 12px;
        border-color: #222;
      }
      font-size: 14px;
    }
    .token-select {
      width: 110px;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #222;
      box-shadow: none;
    }
    .ant-select-selection-search {
      margin-left: 0;
    }
    .reward-input-wrap {
      width: 110px;
      input {
        border-radius: 2px 0px 0px 2px;
        padding-right: 40px;
      }
      .reward-input-icon {
        position: absolute;
        right: -32px;
        height: 40px;
        width: 32px;
        box-shadow: inset -1px 0px 0px #222222, inset 0px 1px 0px #222222, inset 0px -1px 0px #222222;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          display: block;
          width: 8px;
        }
      }

    }
    .table-wrap {
      border: 1px solid #222;
      border-radius: 2px;
      margin: 10px 0;
    }
    .button-group {
      padding-top: 4px;
      .button-item {
        height: 48px;
        line-height: 48px;
        padding: 0 24px;
        border-radius: 1px;
      }
      .secondary-button {
        border: 1px solid #D9D9D9;
      }
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #222;
  background-color: #FFC400;
}
@primary-color: #157AAC;