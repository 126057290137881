.w-120 {
  width: 7.5em;
}
.break-css {
  /* width: 200px; */
  white-space: nowrap;
  word-break: keep-all;
  word-wrap: break-word;
  /* overflow: hidden; */
}
.display-table {
  display: table;
}
