.theme-dark {
  background-color: #000;
  .page-title {
    color: #F0F0F0;
  }
  .page-bg {
    background-color: #000;
  }
  .custom-modal {
    background-color:#212121;
    border-color: #3e3e3e !important;
    color: #fff;
  }
  .custom-form {
    background-color: #181818;
    color: #F0F0F0;
    border-color: #3E3E3E;
  }
  .form-icon {
    color: #F0F0F0;
  }
  .ant-radio-button-wrapper {
    background-color:#181818 !important;
    border-color: #3e3e3e !important;
    color: #fff !important;
  }
  .ant-radio-button-wrapper-checked {
    background-color:#FFC400 !important;
    border-color: #FFC400 !important;
    color: #222 !important;
  }
  .load-more-btn {
    color: #fff;
  }
}
.theme-light {
  background-color: #fff;
  .page-title {
    color: #222222;
  }
  .page-bg {
    background-color: #000;
  }
  .custom-modal {
    background-color:#fff;
    border-color: #EAEAEA;
    color: #222;
  }
  .custom-form {
    background-color: #fff;
    color: #222;
    border-color: #ccc;
  }
  .form-icon {
    color: #222;
  }
  .ant-radio-button-wrapper {
    background-color: #FFFFFF;
    border-color: #B8B8B8;
    color: #222;
  }
  .ant-radio-button-wrapper-checked {
    background-color:#FFC400 !important;
    border-color: #FFC400 !important;
    color: #222 !important;
  }
  .load-more-btn {
    color: #000;
  }
}

@primary-color: #157AAC;