.bounty-component-wrapper {
  .button-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #222;
    line-height: 1;
    margin-bottom: 40px;
    .button-item {
      display: inline-block;
      padding: 0 16px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-weight: 600;
      text-align: center;
    }
    .button-item.basic {
      background: #FFC400;
      border-color: #FFC400;
    }
    .ant-radio-button-wrapper {
      margin-right: 8px;
      color: #222;
      font-weight: 600;
      border-color: #B8B8B8;
      border-radius: 2px;
      border-width: 1px;
      .ant-radio-button {
        border-color: #B8B8B8;
      }
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
    .ant-radio-button-wrapper-checked {
      border-color: #FFC400;
      background: #FFC400;
      box-shadow: none;
      color: #222;
    }
    .right {
      min-width: 160px;
    }
  }
  .request-wrap {
    .request-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), 0px -1px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 20px;
      box-sizing: border-box;
      padding: 24px 24px 38px 24px;
      .right {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        .head {
          .title-wrap {
            display: flex;
            align-items: center;
            line-height: 1;
            margin-bottom: 8px;
            background: transparent;
            .title {
              font-size: 24px;
              margin-bottom: 0;
              line-height: 150%;
              font-weight: 800;
            }
            .tag {
              margin-left: 12px;
              padding: 4px;
              font-weight: 700;
              font-size: 16px;
              line-height: 120%;
              border-radius: 4px;
            }
            .tag-green {
              background: #D2FFB7;
              color: #47AB0A;
            }
            .tag-grey {
              background: #E8E8E8;
              color: #666666;
            }
            .tag-basic {
              background: #FFC400;
              color: #222222;
            }
          }
          margin-bottom: 8px;
        }
        .foot {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr 0.2rem 0.2rem 1fr;
          grid-column-gap: 0.75rem;
          .info-item {
            font-weight: 700;
            line-height: 1;
            color: #222;
            text-align: left;
            &__title {
              color: #888888;
              padding-bottom: 14px;
            }
          }

          .request-info {
            grid-column-start: 1;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 16px;
            color: #888888;
            font-weight: 600;
            &-img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: block;
            }
          }
          .time-info {
            grid-column-start: 2;
            display: flex;
            align-items: center;
            transform: translateY(-50%);
            color: #888888;
            font-weight: 600;
            font-size: 14px;
          }
          .winner-info {
            grid-column-start: 1;
            grid-row-start: 4;
            text-align: left;
          }
          .submit-info {
            grid-column-start: 2;
            grid-row-start: 4;
            width: fit-content
          }
          .offer-info {
            white-space: nowrap;
            grid-column-start: 3;
            grid-row-start: 4;
          }
        }
      }
    }
  }
}
.theme-dark {
  .bounty-component-wrapper {
    .info-item__value {
      color: #fff;
    }
  }
}
.theme-light {
  .bounty-component-wrapper {
    .info-item__value {
      color: #222;
    }
  }
}
@primary-color: #157AAC;