.mt-fu-50 {
  margin-top: -120px;
}
.w-125 {
  width: 12.5rem;
}
.w-40per {
  width: 40%;
}
.mt-fu-20 {
  margin-top: -5em;
}
.border-style {
  border: 1px solid #222222;
}
.text-size-16 {
  font-size: 16px;
}
.text-size-20 {
  font-size: 20px;
}
.text-size-18{
  font-size: 18px;
}
.height-170 {
  height: 10.625em;
}
.display-inherit {
  display: inherit;
}
.font-color-222 {
  color: #222222;
}
.font-color-4e {
  color: #4e4e4e;
}
.font-color-000 {
  color: #000000;
}
.h-520 {
  height: 32.5em;
}
.overflow-scoll {
  overflow-x: scroll;
  overflow-y: hidden;
}
.max-h-320 {
  max-height: 320px;
}
.white-space-nowrap{
  white-space: nowrap;
}
.height-76{
  height: 76px;
}
.height-40{
  height: 40px;
}
.w-140{
  width: 140px;
}