.upload-work-wrapper {
  padding-bottom: 120px;
  .button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .main-btn {
      color: #222;
    }
  }
  .upload-content {
    max-width: 842px;
    margin: 0 auto;
    .title {
      &-main {
        color: #ccc;
        font-weight: 700;
        font-size: 2rem;
        line-height: 44px;
      }
      &-tips {
        color: #888;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        padding-top: 12px;
      }
    }
    textarea {
      color: #222;
      font-size: 16px;
      padding: 0;
    }
    .content {
      position: relative;
      text-align: center;
      margin: 32px auto;
      background: #FAFAFA;
      border: 1px dashed #888888;
      border-radius: 2px;
      .link {
        color: #0085FF;
        cursor: pointer;
      }
      .iconfont {
        line-height: 1;
      }
      .icon-upload-default {
        font-size: 100px;
        padding-bottom: 20px;
      }
      .tips {
        line-height: 20px;
        margin-top: 12px;
      }
      .ant-upload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #222;
      }
      .controls-wrap {
        width: 100%;
        padding: 0 24px;
        box-sizing: border-box;
        position: absolute;
        bottom: 30px;
        left: 0;
        .controls {
          height: 48px;
          border-radius: 2px;
          padding: 0 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #222222;
          .iconfont {
            font-size: 16px;
            color: #888888;
          }
          .iconfont:hover {
            color: #fff;
          }
          .zoom-range {
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 2px;
            border-top: 2px solid #484848;
            border-bottom: 2px solid #484848;
            border-radius: 2px;
            background: #484848;
            width: 100%;
            margin: 0 14px 0;
          }
          .slider {
            padding: 22px 0px;
          }
          .zoom-range::-moz-range-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #fff;
            background: #fff;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
          .zoom-range::-webkit-slider-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #fff;
            background: #fff;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }
        .controls:hover input[type='range']::-webkit-slider-thumb {
          box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
          border-radius: 50%;
        }
        .controls:hover input[type='range']::-moz-range-thumb {
          box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
        }
      } 
      .delete {
        width: 56px;
        height: 56px;
        position: absolute;
        top: 16px;
        right: 16px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        cursor: pointer;
        .iconfont {
          font-size: 20px;
          color: #fff;
        }
      }
      video {
        width: 630px;
        height: 630px;
        object-fit: contain;
      }
    }
    .proposal-content {
      margin: 12px auto 32px;
    }
  }
}
.tag-custom-modal {
  .main-btn, .sub-btn {
    font-size: 16px;
    color: #222;
  }
  .sub-btn {
    font-weight: 400;
    margin-right: 8px;
  }
  .main-btn {
    font-weight: 800;
  }
}
.ant-select-dropdown {
  z-index: 99999 !important
}
@primary-color: #157AAC;