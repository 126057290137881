.bounty-detail-wrapper {
  .title-wrap {
    .title {
      color: #222222;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .tips {
      color: #888888;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
    }
    margin-bottom: 22px;
  } 
  .info-wrap {
    display: flex;
    margin-bottom: 40px;
    .info-item {
      font-weight: 700;
      line-height: 1;
      width: 33.33%;
      text-align: left;
      &__title {
        color: #888888;
        margin-bottom: 14px;
      }
    }
  }
  .request-wrap {
    &-content {
      .content-item {
        position: relative;
        overflow: hidden;
        display: inline-block;
        .m-auto {
          width: 100%;
          height: 100%;
        }
        &__img, img {
          width: 100%;
        }
        &__tips {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          padding: 20px 12px;
          width: 100%;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          bottom: 0;
          color: #fff;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
        &__top_tips {
          background: #ccc;
          padding: 10px 12px;
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 0;
          // color: #fff;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
        &__video {
          position: relative;
          .icon-play {
            line-height: 1;
            font-size: 80px;
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;        
            cursor: pointer;  
          }
        }
        .choose-cover {
          position: absolute;
          width: 100%;
          height: 100%;
          // background: rgba(0, 0, 0, 0.6);
          top: 0;
          left: 0;
          box-sizing: border-box;
          padding: 8%;
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #222;
          }
          .ant-checkbox-inner::after {
            left: 29.5%;
          }
          .ant-checkbox-checked::after {
            border:none;
            box-shadow: none;
          }
          .ant-checkbox-checked  {
            .ant-checkbox-inner{
              background-color: #FFC400;
              border: none;
            }
            .ant-checkbox-inner::after {
              border-color: #222;
            }
          }

          .ant-checkbox-disabled {
            .ant-checkbox-inner {
              border-color: #222 !important;
              opacity: 0.5;
            }
          }
          
          .ant-checkbox-inner:hover, .ant-checkbox-input:hover {
            box-shadow: none;
            outline: none;
          }
        }
        &-disabled {
          opacity: 0.5;
        }
        .winner-tag {
          position: absolute;
          top: 8%;
          left: 5%;
          background: #FFC400;
          font-weight: 700;
          font-size: 16px;
          line-height: 120%;
          padding: 4px 8px;
          color: #222222;
          border-radius: 2px;
        }
      }
    }
    .ant-checkbox-group {
      width: 100%;
    }
  }
  
  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000;
    .primary-button, .secondary-button {
      font-size: 16px;
      line-height: 1;
      padding: 12px 16px;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 2px;
    }
    .primary-button {
      background: #FFC400;
      font-weight: 600;
      
    }
    .secondary-button {
      border: 1px solid #d9d9d9;
    }
  }
  .request-wrap {
    padding: 40px 0;
    &-content {
      display: grid;
    }
  }
}
@primary-color: #157AAC;