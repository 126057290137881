.checkcode-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #222;
  .code-content-box {
    text-align: center;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .error-tips {
      color: #FD0000;
      font-size: 12px;
      line-height: 16px;
      padding-top: 12px;
      text-align: left;
    }
    .captcha-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 40px 0;
      .title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
    .main-button {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFC400;
      border-radius: 2px;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 48px;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      img {
        width: 16px;
        margin-left: 12px;
        display: block;
      }
    }
    
    
    .tips {
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      font-weight: 400;
      &-link {
        color: #0085FF;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
@primary-color: #157AAC;