.space-detail-wrapper {
  .title-wrap {
    background-size: cover;
    background-position: center center;
    background-color: #000;
    background-repeat: no-repeat;
    box-sizing: border-box;
    .title-box {
      backdrop-filter: blur(5px);
      padding: 24px 0;
      .title-content {
        display: flex;
        align-items: center;
        .title-text {
          font-size: 48px;
          line-height: 150%;
          margin-bottom: 12px;
          font-weight: 700;
          color: #fff;
          text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
          padding-left: 40px;
        }
        .title-img {
          width: 96px;
          height: 96px;
          display: block;
          border-radius: 50%;
        }
      } 
    }
  }
  .tabs-wrap {
    padding-bottom: 30px;
    .ant-tabs-nav {
      margin-bottom: 20px;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-color: #3F3F3F;
    }
    .ant-tabs-tab {
      padding: 24px 24px 8px 0;
      font-size: 16px;
      color: #c5c5c5;
      font-weight: 700;
      margin: 0;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #3F3F3F;
    }
    .ant-tabs-tabpane {
      text-align: center;
    }
    .number {
      color: #B9B9B9;
      font-weight: 400;
      padding-left: 2px;
    }
  }
  .load-more-btn {
    width: 150px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: 1px solid #3E3E3E;
    border-radius: 1px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    margin: 60px auto 0;
    cursor: pointer;
  }
}
@primary-color: #157AAC;