.grid-css{
    flex-grow: 1;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin: 10px;
}
.over-css{
    overflow:hidden; 
    // text-overflow:ellipsis; 
    white-space:nowrap; 
}
.text-overflow-ellipsis{
    text-overflow:ellipsis; 
}
.avatar{
    width: 24px;
    height: 24px;
}
.height-236{
    height: 236px;
}
@primary-color: #157AAC;