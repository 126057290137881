.proposal-index-wrapper {
  .content {
    max-width: 842px;
    margin: 0 auto;
    .title-wrap {
      margin-bottom: 32px;
      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 44px;
        margin-bottom: 20px;
      }
      .author {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #888;
        margin-bottom: 20px;
        img {
          width: 20px;
          height: 20px;
          display: block;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
    }
    .img-wrap {
      width: 100%;
    }
  }
  
}
@primary-color: #157AAC;