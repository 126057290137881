.codebox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 24px;
  .codebox-field-wrap {
    display: inline-block;
    width: 56px;
    height: 56px;
    input {
      width: inherit;
      height: inherit;
      line-height: inherit;
      font-size: 32px;
      text-align: center;
      vertical-align: baseline;
      border: none;
      outline: none;
      color: #000000;
      border-radius: 1px;
      font-weight: 700;
      background: #EEEEEE;
    }
    input:focus, .fill {
      border: 2px solid #000;
    }
    input.fill.wrong {
      border-color: #FD0000;
    }
    
  }
  .codebox-field-wrap:not(:last-child) {
    // margin-right: 24px;
  } 
}

/* iphone SE */
// @media (max-width: 320px) {
//   .codebox-container .codebox-field-wrap {
//     margin: 0.4rem;
//     width: 2rem;
//     height: 2rem;
//   }
// }
@primary-color: #157AAC;