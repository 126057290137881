.item-h-341 {
  height: 21.3em;
  width: 21.3em;
}
.item-h-170 {
  height: 10.625em;
  /* width: 10.625em; */
}
/* .item-h-117 {
  height: 7.3em;
  width: 7.3em;
} */
.img-300 {
  width: 18.75em;
  height: 18.75em;
}
.img-170 {
  height: 10.625em;
  /* width: 10.625em; */
}
.width-27 {
  width: 1.68em;
  height: 1.68em;
}

.height-27 {
  height: 1.68em;
}
.text-color-fff {
  color: #ffffff;
}
