.clear-both {
  clear: both;
}
.text-color-888 {
  color: #888888;
}
.over-hidden {
  overflow: hidden;
}
.border-css {
  border: 1px solid #eaeaee;
}
.m-h-32 {
  height: 128px;
}
.h-25 {
  height: 25px;
}
.mt-fu {
  margin-top: -20px;
}
.font-size-32 {
  font-size: 32px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.ont-size-18 {
  font-size: 18px;
}
.height-88 {
  height: 5.5em;
}
.w-405 {
  width: 25.31em;
}
.w-88 {
  width: 5.5em;
}

.overflow-text {
  /* width: 200px; */
  overflow: hidden;
  /* border: 1px solid white; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
}
.overflow-line-1 {
  -webkit-line-clamp: 1;
}
.overflow-line-2 {
  -webkit-line-clamp: 2;
}
.chakra-avatar__excess {
  background-color: #666666 !important;
  font-size: 14px !important;
}
.chakra-avatar__initials {
  font-size: 14px !important;
}
.h-43 {
  height: 43px;
}
.max-w-384 {
  max-width: 384px;
}
